@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .selectOption.selectOption {
    @apply p-2 bg-gray-200 rounded pl-8;
  }
  .selectOption .bp4-control-indicator {
    transform: translate3d(2px, 0, 0);
  }
  .bp4-form-group.horizontal .bp4-form-content,
  .radioGroup--horizontal {
    @apply flex w-full;
  }
  .selectOption--horizontal.selectOption--horizontal {
    @apply text-center text-sm pl-2 flex-1;
  }
  .selectOption--horizontal.selectOption--horizontal .bp4-control-indicator {
    @apply m-auto p-0 block text-center;
  }
  .selectOption--horizontal .selectOption--displayText {
    @apply block text-center;
  }
  .selectOption--selected.selectOption--selected {
    @apply bg-vanda-green;
  }
  .bp4-control.bp4-inline:last-child {
    @apply mr-0;
  }
  .promptType__content p,
  .promptType__content details {
    @apply my-4;
  }
  .promptType__content details {
    @apply p-2 bg-blue-400 border border-blue-400 rounded text-blue-700;
  }
  .promptType__content summary {
    @apply cursor-pointer font-bold;
  }
  .promptType__content summary:hover {
    @apply underline;
  }
  .promptType__content summary ~ * {
    @apply my-2 px-4;
  }
  .promptType__content details:last-child {
    @apply mb-0;
  }
}
 .promptType__content summary:hover {
  @apply underline;
}

.PhoneInputInput {
  @apply border-t-2 border-b-2 border-transparent h-6 focus:outline-none;
}

.PhoneInput--focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgb(45 114 210 / 30%), inset 0 1px 1px rgb(17 20 24 / 20%);
}
.bp4-button:focus {
  outline:  none;
} 